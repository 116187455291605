<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12" md="9">
      <v-card>
        <v-card-title class="py-9 px-8">
          <span> 契約(新規) </span>
        </v-card-title>

        <v-form class="ma-6">
          <!-- <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label
                class="text-right"
                for="invoiceID"
              >契約ID<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model.number="invoiceForm.id"
                outlined
                disabled
                dense
                class="header-inputs flex-grow-0"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label
                class="text-right"
                for="contract_date"
              >契約日<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-menu
                v-model="isIssueDateMenuOpen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                eager
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="invoiceForm.issuedDate"
                    class="header-inputs flex-grow-0"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="invoiceForm.issuedDate" @input="isIssueDateMenuOpen = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label
                class="text-right"
                for="contract_date"
              >Due Date<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-menu
                v-model="isDueDateMenuOpen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                eager
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="invoiceForm.dueDate"
                    class="header-inputs flex-grow-0"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="invoiceForm.dueDate" @input="isDueDateMenuOpen = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label
                class="text-right"
                for="invoiceID"
              >取引先ID<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="invoiceForm.client"
                :items="clients"
                outlined
                clearable
                dense
                placeholder="customer ID"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="12">
              <div class="add-products-form pt-4 pb-10">
                <div
                  v-for="(item, index) in invoiceForm.contract_details"
                  :key="index"
                  class="single-product-form"
                >
                  <div class="add-products-header mb-2 d-none d-md-flex">
                    <div class="px-5 flex-grow-1 font-weight-semibold">
                      <v-row>
                        <v-col cols="12" md="6">
                          <span>商品ID</span>
                        </v-col>
                        <v-col cols="12" md="2">
                          <span>数量</span>
                        </v-col>
                        <v-col cols="12" md="2">
                          <span>重量</span>
                        </v-col>
                        <v-col cols="12" md="2">
                          <span>価格</span>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="header-spacer"></div>
                  </div>
                  <v-card outlined class="d-flex">
                    <!-- Left Form -->
                    <div class="pa-5 flex-grow-1">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="item.productId"
                            outlined
                            dense
                            :items="productIds"
                            item-text="productId"
                            :menu-props="{ offsetY: true }"
                            placeholder="Select Item"
                            label="Select Item"
                            hide-details="auto"
                            return-object
                            class="mb-3"
                          ></v-select>
                          <v-textarea
                            v-model="item.description"
                            outlined
                            label="Description"
                            rows="3"
                            hide-details="auto"
                            placeholder="Description"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="2" sm="4">
                          <amount-input
                            v-model="item.amount"
                            :outlined="true"
                          ></amount-input>
                        </v-col>
                        <v-col cols="12" md="2" sm="4">
                          <weight-input
                            v-model="item.weight"
                            :outlined="true"
                          ></weight-input>
                        </v-col>
                        <v-col cols="12" md="2" sm="4">
                          <price-input
                            v-model="item.price"
                            :outlined="true"
                          ></price-input>
                        </v-col>
                      </v-row>
                    </div>
                    <v-divider
                      vertical
                    ></v-divider>
                    <!-- Item Actions -->
                    <div class="d-flex flex-column item-actions rounded-0 pa-1">
                      <v-btn icon small @click="removeContract(item)">
                        <v-icon size="20">
                          {{ icons.mdiClose }}
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn icon small>
                        <v-icon size="20">
                          {{ icons.mdiCogOutline }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-card>
                </div>
                <v-btn color="primary" class="mt-4" outlined @click="addContract">
                  Add Item
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col cols="12" md="3">
      <v-card class="mb-6">
        <v-card-text>
          <v-btn color="primary" class="mb-3" block>
            Save
          </v-btn>
          <v-btn class="mb-3" color="secondary" block outlined>
            Preview
          </v-btn>
          <v-btn color="secondary" block outlined>
            Cancel
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiSendOutline,
  mdiPlus,
  mdiPencil,
  mdiClose,
  mdiCogOutline,
  mdiMinusCircle,
} from '@mdi/js'
import {
  ref,
  reactive,
} from '@vue/composition-api'
import WeightInput from '@/components/WeightInputComponent.vue'
import PriceInput from '@/components/PriceInputComponent.vue'
import AmountInput from '@/components/AmountInputComponent.vue'

export default {
  components: {
    WeightInput,
    PriceInput,
    AmountInput,
  },
  setup() {
    const invoiceForm = reactive({
      id: 5037,
      issuedDate: '',
      service: '',
      total: '',
      avatar: '',
      invoiceStatus: '',
      balance: '',
      dueDate: '',
      client: {
      },
      contract_details: [
        {
          productId: '',
          amount: 0,
          weight: '',
          price: 0,
          description: '',
        },
      ],
    })
    const items = []
    const isIssueDateMenuOpen = ref(false)
    const isDueDateMenuOpen = ref(false)
    const editStatus = ref(false)
    const clients = ref([])

    const setEditStatus = () => {
      editStatus.value = true
      console.log('---------->setName', editStatus.value)
    }

    const addContract = () => {
      console.log('addCOntract', invoiceForm)
      const detailId = invoiceForm.contract_details.length + 1
      invoiceForm.contract_details.push({
        id: detailId,
        detail_id: detailId,
        product_id: '',
        contract_goods_name: '',
        contract_weight: '',
        contract_price: '',
        contract_note: '',
      })
    }

    const removeContract = item => {
      console.log(invoiceForm.contract_details.indexOf(item))
      if (invoiceForm.contract_details.indexOf(item) !== -1) {
        invoiceForm.contract_details.splice(invoiceForm.contract_details.indexOf(item), 1)
        const orderContracts = []
        invoiceForm.contract_details.forEach((v, i) => {
          orderContracts[i] = v
          orderContracts[i].detail_id = i + 1
          console.log(i, v.detail_id, v.detail_id)
        })
      } else {
        console.log('removeContract-----> item does not exist')
      }
    }

    return {
      invoiceForm,
      isIssueDateMenuOpen,
      isDueDateMenuOpen,
      items,
      editStatus,
      clients,

      // Icons
      icons: {
        mdiSendOutline,
        mdiPlus,
        mdiPencil,
        mdiMinusCircle,
        mdiClose,
        mdiCogOutline,
      },

      setEditStatus,
      addContract,
      removeContract,
    }
  },
}
</script>

<style lang="scss">
.add-products-form {
  .single-product-form {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
}
</style>
